import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-services-cards",
  templateUrl: "./services-cards.component.html",
  styleUrls: ["./services-cards.component.css"],
})
export class ServicesCardsComponent implements OnInit {
  ServiceContent = [
    "Advanced Nutritional Supplements: Offering a range of specialized supplements formulated to enhance milk production and boost immunity in pet animals.",
    "Tailored Solutions: Providing customized nutritional plans and products tailored to meet the unique needs of different breeds and individual pet",
    "Scientific Expertise: Leveraging cutting-edge research and veterinary expertise to develop innovative and effective solutions for pet health and well-bein",
    "Quality Assurance: Ensuring the highest standards of quality and safety through rigorous testing, quality control measures, and compliance with regulatory standard",
    "Product Education: Providing comprehensive educational resources and guidance to pet owners on the proper use and benefits of our product",
    "Veterinary Support: Collaborating with veterinarians to offer professional guidance and support in addressing specific health concerns and optimizing pet nutritio",
    "Continuous Improvement: Committing to ongoing research and development to stay at the forefront of pet nutrition and continually improve our products and service",
    "Customer Care: Offering exceptional customer service and support to address inquiries, provide product recommendations, and ensure a positive experience for pet owner",
    "Sustainability: Embracing eco-friendly practices and sustainable sourcing to minimize environmental impact and promote responsible pet car",
    "Community Engagement: Engaging with pet owners, breeders, and animal welfare organizations to foster a community dedicated to promoting the health and happiness of pets everywhere.",
  ];
  constructor() { }

  ngOnInit() { }
}
