import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footerv2',
  templateUrl: './footerv2.component.html',
  styleUrls: ['./footerv2.component.css']
})
export class Footerv2Component implements OnInit {
  year = 2024;

  constructor() {
    const d = new Date();
    this.year = d.getFullYear();
  }

  ngOnInit() {
  }

}
