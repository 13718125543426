import { HtmlParser } from "@angular/compiler";

export const ContactWhatsappBaseHref = `https://wa.me/919325560646/?text=Hi,%0A%0AI%20saw%20a%20Product%20on%20the%20VetPro%20Website,%20I%20want%20to%20get%20more%20information%20related%20to%20the%20same,%0A%0AProduct:%20`

export interface Product {
    title: string,
    description?: string,
    description2?: string,
    imagePath?: string,
    productNumber?: string,
    contactLink?: string,
    packaging?: string,
    subProducts?: Product[]
    text1?: string;
    text2?: string;
    text3?: string;
    text4?: string;
}

export interface BannerImages {
    sortOrder: number,
    imagePath: string,
    alt: string,
    desc?: string,
}

export const products: Product[] = [];

export const products2: Product[] =
    [
        {
            title: "CALCIVET-GOLD",
            text1: `जनावरांमध्ये कॅल्शियम ची गरज.`,
            text2: `गायी व म्हशींच्या गर्भावस्थेत कॅल्शिअम, फॉस्फरस व व्हिटामिन ची मोठ्या प्रमाणात आवश्यकता असते.व्यालानंतर ती गरज अनेक पटीने वाढते.त्यासाठी जनावरांच्या आहारात कॅल्शियम देणे गरजेचे असते.`,
            text3: `कैल्सीवेट गोल्ड प्लस चे फायदे.`,
            text4: `•⁠  ⁠दूध उत्पादनात १००% वाढ होते.
            •⁠  ⁠भरपूर कॅल्शिअम व फॉस्फरसयुक्त.
            
            •⁠  ⁠दुधाची फॅट व एस एन एफ सुधारते.
            
            •⁠  ⁠जनावरांच्या अंगावर चकाकी राहते.
            
            •⁠  ⁠वेळेवर माजावर येण्यास मदत होते.
            
            •⁠  ⁠वाढलेल्या दुधाचे सातत्य टिकवून ठेवते.`,

            description2: ``,
            subProducts: [
                {
                    imagePath: "/assets/products-images-2/CALCIVET-GOLD-1L.png",
                    title: "CALCIVET-GOLD-1L",
                    description: "CALCIVET GOLD PLUS 1 LTR",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}CALCIVET GOLD PLUS 1 LTR`,
                    packaging: "1 LTR"
                },
                {
                    imagePath: "/assets/products-images-2/CALCIVET-GOLD-PLUS-5L.png",
                    title: "CALCIVET-GOLD-PLUS-5L",
                    description: "CALCIVET GOLD PLUS 5 LTR",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}CALCIVET GOLD PLUS 5 LTR`,
                    packaging: "5 LTR"
                },
                {
                    imagePath: "/assets/products-images-2/CALCIVET-GOLD-PLUS-20L.png",
                    title: "CALCIVET-GOLD-PLUS-20L",
                    description: "CALCIVET GOLD PLUS 20 LTR",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}CALCIVET GOLD PLUS 20 LTR`,
                    packaging: "20 LTR"
                },
            ]
        },
        {
            title: "GROW-WELL",
            text1: `ग्रो-वेल ची गरज.`,
            text2: `लहान जनावरांचे चारा खाण्याचे प्रमाण कमी असते. त्यामुळे खनिजांचा पुरवठा होत नाही. त्या खनिजांचा पुरवठा करण्याचे काम ग्रोवेल करते.`,
            text3: `ग्रो-वेल चे फायदे.`,
            text4: `•⁠  ⁠वजन वाढीसाठी उपयुक्त ठरते.
            
            •⁠  ⁠वासरांचे वजन वाढते.
            
            •⁠  ⁠शरीररचना चांगली बनून मरतुकीचे प्रमाण कमी होते.
            
            •⁠  ⁠रोगप्रतकारशक्ती वाढण्यास मदत होते.
            
            •⁠  ⁠अपचन व पोटफुगीचे आजार टाळले जातात.
            
            •⁠  ⁠हाडे मजबूत बनतात.
            
            •⁠  ⁠कालवड संगोपनाच्या खर्चात बचत होते.`,
            subProducts: [
                {
                    imagePath: "/assets/products-images-2/GROW-WELL-1L.png",
                    title: "GROW-WELL-1L",
                    description: "GROW WELL 1 LTR",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}GROW WELL 1 LTR`,
                    packaging: "1 LTR"
                },
                {
                    imagePath: "/assets/products-images-2/GROW-WELL-5L.png",
                    title: "GROW-WELL-5L",
                    description: "GROW WELL 5 LTR",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}GROW WELL 5 LTR`,
                    packaging: "5 LTR"
                },
            ]
        },
        {
            title: "MULTIVITA-STRONG",
            text1: `मिनरल मिक्चरची गरज`,
            text2: `जनावरांची गरज लक्षात घेऊन जनावरांच्या रोजच्या चाऱ्यातून व आहारातून सर्व खनिज घटकांची उपलब्धता होत नाही. त्या खनिज घटकांची कमतरता मिनरल मिक्चर भरून काढते.`,
            text3: `मल्टीविटा स्ट्रॉग चे फायदे.`,
            text4: `•⁠  ⁠जनावरांचा कोटा सुधारण्यास मदत होते.
            
            •⁠  ⁠दूध व फॅट वाढते.
            
            •⁠  ⁠प्रजनन क्षमता वाढते.
            
            •⁠  ⁠वेळेवर गर्भधारणा होते.
            
            •⁠  ⁠सर्व मिनरल घटक व व्हिटॅमिन मिळतात.
            
            •⁠  ⁠गर्भाशया संबंधित आजार टाळले जातात.
            
            •⁠  ⁠पचनसंस्था सुधारते.
            
            •⁠  ⁠जनावरांची भूक वाढते.`,
            subProducts: [
                {
                    imagePath: "/assets/products-images-2/MULTIVITA-1KG.png",
                    title: "MULTIVITA-STRONG-1KG",
                    description: "MULTIVITA STRONG 1 KG",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}MULTIVITA STRONG 1 KG`,
                    packaging: "1 KG"
                },
                {
                    imagePath: "/assets/products-images-2/MULTIVITA-5KG.png",
                    title: "MULTIVITA-STRONG-5KG",
                    description: "MULTIVITA STRONG 5 KG",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}MULTIVITA STRONG 5 KG`,
                    packaging: "5 KG"
                },
            ]
        },
        {
            title: "LIV-CARE",
            description: "LIV CARE",
            text1: `लिव्ह-केअर ची गरज.`,
            text2: `यकृताची कार्यक्षमता वाढविण्यासाठी व जनावरांच्या शरीरातील रक्त वाढवण्यासाठी लिव्हकेअर चा वापर होतो.`,
            text3: `लिव्ह-केअर चे फायदे.`,
            text4: `•⁠  ⁠हिमोग्लोबीन चे प्रमाण वाढते.
            
            •⁠  ⁠रक्तवाढीसाठी मदत होते.
            
            •⁠  ⁠लिव्हर ची कार्यक्षमता वाढते.
            
            •⁠  ⁠जनावरे आजारपणातून लवकर बरे होतात.
            
            •⁠  ⁠दूध वाढण्यास मदत होते.
            
            •⁠  ⁠रोग प्रतिकारक शक्ती वाढते.`,
            subProducts: [
                {
                    imagePath: "/assets/products-images-2/LIV-CARE-1L.png",
                    title: "LIV-CARE-1L",
                    description: "LIV-CARE 1 LTR",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}LIV-CARE 1 LTR`,
                    packaging: "1 LTR"
                },
                {
                    imagePath: "/assets/products-images-2/LIV-CARE-5L.png",
                    title: "LIV-CARE-5L",
                    description: "LIV-CARE 5 LTR",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}LIV-CARE 5 LTR`,
                    packaging: "5 LTR"
                },
            ]
        },
        {
            imagePath: "/assets/products-images-2/VITAL-SUPER-H-1L.png",
            title: "VITAL-SUPER-H-1L",
            description: "VITAL SUPER-H 1 LTR",
            description2: "",
            productNumber: "",
            contactLink: `${ContactWhatsappBaseHref}VITAL SUPER-H 1 LTR`,
            text1: `व्हायटल सुपर एच ची गरज.`,
            text2: `प्रत्येक दूधउत्पादक शेतकरी दुधाचे जास्तीत जास्त उत्पादन घेण्याचा प्रयत्न करत आहे. त्यासाठी ते जनावरांना जास्त प्रमाणात सकस आहार देतात. त्याचा परिणाम जनावरांच्या दुध वाढीवर होतो. पण कासपेर्शी मधील स्तन दाह / आंत्र दाह यांसारखे आजार कासपेशीवर होतात. त्याला आळा घालण्यासाठी व्हायटल सुपर एच चा वापर होतो.`,
            text3: `व्हायटल सुपर एच चे फायदे.`,
            text4: `•⁠  ⁠जनावरांच्या कासेची वाढ होऊन दुध उत्पादनात वाढ होते.
            
            •⁠  ⁠मस्टायटिस होण्यापासून संरक्षण होते.
            
            •⁠  ⁠दुधाची प्रत सुधारते.
            
            •⁠  ⁠कास पेशींमधील वाहिन्यांचा रक्तप्रवाह वाढवते.
            
            •⁠  ⁠जनावरांचे वाढलेले दुधाचे सातत्य टिकवून ठेवते.`,
        },
        {
            imagePath: "/assets/products-images-2/DYZACURE-300ML.png",
            title: "DYZACURE-300ML",
            description: "DYZACURE 300ml",
            description2: "",
            productNumber: "",
            contactLink: `${ContactWhatsappBaseHref}DYZACURE 300ml`,
            text1: `जनावरांमध्ये डायझाक्युअर ची गरज.`,
            text2: `रोजच्या चाऱ्यातून व आहारातून अपचन, पोट फुगणे. तसेच पोटाचे अनेक विकार होत असतात. डायझेस्टीव इम्युनिटी स्ट्रॉग करण्यासाठी डायझाक्युअरचा वापर करणे गरजेचे आहे.`,
            text3: `डायझाक्युअर चे फायदे.`,
            text4: `•⁠  ⁠जनावरांच्या पचनसंस्थे संबंधित सर्व विकारांवर उपयुक्त ठरते.
            
            •⁠  ⁠जनावरांच्या पोटफुगीवर डायझाक्युअरचा उपयोग केला जातो.
            
            •⁠  ⁠दररोजच्या आहाराचे योग्य पद्धतीने पचन होते.
            
            •⁠  ⁠पोट गॅस धरणे व कब्ज होणे अशा समस्यांवर
            
            डायझाक्युअर उपयुक्त ठरते.
            
            •⁠  ⁠पाचक रोगप्रतिकारक शक्ती मजबूत होते.`,
        },
        {
            imagePath: "/assets/products-images-2/CALCIVET-300ML.png",
            title: "CALCIVET-300ML",
            description: "CALCIVET 300ml",
            description2: "",
            productNumber: "",
            contactLink: `${ContactWhatsappBaseHref}CALCIVET 300ml`,
            text1: `कॅल्सीवेट जेल ची गरज.`,
            text2: `विण्यापूर्वी संक्रमण काळातील आहार व आहारपूर्व घटकांच्या अयोग्य व्यवस्थापनामुळे विल्यानंतर जनावरांमधील कॅल्शियमची मात्रा कमी होते. कॅल्शियमची कमी झालेली मात्रा कॅल्सीवेट जेल भरून काढते. `,
            text3: `कॅल्सीवेट जेल चे फायदे.`,
            text4: `•⁠  ⁠वार पाडण्यासाठी मदत होते.
            
            •⁠  ⁠जनावरांची हाडे व दात मजबूत करण्यास मदत करते.
            
            •⁠  ⁠कॅल्शियम मॅग्नेशियम व फॉस्फरस ची कमतरता भरून निघते.
            
            •⁠  ⁠गर्भपिशवी लवकर पूर्ववत होते.
            
            •⁠  ⁠जनावरांची भूक वाढण्यास मदत होते.
            
            •⁠  ⁠पाना सोडण्यास मदत होते.`,
        },
        {
            imagePath: "/assets/products-images-2/BIO-KOL-500ML.png",
            title: "BIO-KOL-500ML",
            description: "BIO - KOL 500 ML",
            description2: "",
            productNumber: "",
            contactLink: `${ContactWhatsappBaseHref}BIO - KOL 500 ML`,
            text1: `बायो कोल ची गरज.`,
            text2: `जनावरामध्ये खनिजांच्या कतरते मुळे जनावरांचे चारा खाण्याचे प्रमाण कमी होते. तसेच चाऱ्यातून होणारी विषबाधा त्यामुळे जनावरांना होणारे पोटासंबंधिचे विकार दूर करण्यासाठी चारकोलची गरज असते.`,
            text3: `बायो कोल चे फायदे.`,
            text4: `•⁠  ⁠विष बाधेवर उपयुक्त ठरते.
            
            •⁠  ⁠आजारपणात दिल्या जाणाऱ्या औषधांचे प्रमाण जास्त झाल्यास नियंत्रित आणते.
            
            •⁠  ⁠पचनासंबंधित विकार दूर होतात.
            
            •⁠  ⁠रवंथ सुधारून पचन क्रिया सुरळीत होते.`,
        },
        {
            imagePath: "/assets/products-images-2/MILK-BOOSTER-GOLD-PLUS-1L.png",
            title: "MILK-BOOSTER-GOLD-PLUS-1L",
            description: "MILK BOOSTER GOLD PLUS 1 LTR",
            description2: "",
            productNumber: "",
            contactLink: `${ContactWhatsappBaseHref}MILK BOOSTER GOLD PLUS 1Ltr`,
            text1: `मिल्क बुस्टरची गरज.`,
            text2: `जनावरांना रोजच्या आहारातून किंवा चाऱ्यातून व्हिटॅमिन्स, मिनरल्स व सर्व खनिजे योग्य प्रमाणात मिळत नसल्याने दुधाचे प्रमाण कमी होते. तसेच जनावरांची रोगप्रतिकारक शक्ती कमी होत असते. यासाठी मिल्क बूस्टर फायदेशीर ठरते.`,
            text3: `मिल्क बुस्टरचे फायदे.`,
            text4: `•⁠  ⁠दुधाळ जनावरांचे दुधाचे प्रमाण वाढते.
            
            •⁠  ⁠दुधाची फॅट व एस एन एफ वाढण्यास मदत होते.
            
            •⁠  ⁠वनौषधी युक्त असल्याने दुध व रोगप्रतिकारक शक्ती वाढते.
            
            •⁠  ⁠वाढलेल्या दुधाचे प्रमाण कायम टिकवून ठेवण्याचे काम करते.
            
            •⁠  ⁠रक्तातील ग्लुकोजची पातळी संतुलीत ठेवण्याचे काम करते.`,
        },
        {
            title: "V-PHOS-PRO",
            text1: `व्ही-फॉस प्रो ची गरज.`,
            text2: `जनावरांच्या शरीरातील हाडे आणि दात मजबूत करण्यासाठी तसेच फॉस्फरस ची कमतरता ही सर्व खनिजामध्ये सर्वात जास्त व्यापक असून त्याच्या कमतरतेमुळे जनावरांवर विपरीत परिणाम होतात यासाठी फॉस्फरस देणे गरजेचे असते.`,
            text3: `व्ही-फॉस प्रो चे फायदे.`,
            text4: `•⁠  ⁠गर्भधारणा आणि दुध वाढीसाठी.
            
            •⁠  ⁠हाडे व दात मजबूत होण्यासाठी उपयुक्त ठरते.
            
            •⁠  ⁠जनावरांच्या शरीरातील चयापचय क्रियेसाठी फॉस्फरस आणि कॅल्शियम ची मदत होते.`,
            subProducts: [
                {
                    imagePath: "/assets/products-images-2/V-PHOS-PRO-500GM.png",
                    title: "V-PHOS-PRO-500G",
                    description: "V-PHOS PRO 500 GM",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}V-PHOS PRO 500 GM`,
                    packaging: "500 GM"
                },
                {
                    imagePath: "/assets/products-images-2/V-PHOS-PRO-500ML.png",
                    title: "V-PHOS-PRO-500M",
                    description: "V-PHOS PRO 500 ML",
                    description2: "",
                    productNumber: "",
                    contactLink: `${ContactWhatsappBaseHref}V-PHOS PRO 500 ML`,
                    packaging: "500 ML"
                },
            ]
        },
        {
            imagePath: "/assets/products-images-2/MUSTGARD-200GM.png",
            title: "MUSTGARD-200GM",
            description: "MUSTGARD 200 GM",
            description2: "",
            productNumber: "",
            contactLink: `${ContactWhatsappBaseHref}MUSTGARD 200 GM`,
            packaging: "200 GM",
            text1: `मस्टगार्ड ची गरज.`,
            text2: `सुप्त अवस्थेतील मस्टायटिस वर नियंत्रण मिळवण्यासाठी तसेच दूध उत्पादनातील घट भरून काढण्यासाठी. तसेच अन्य विषाणूंच्या संक्रमनामुळे कासेची सूज व वेदना यावर मस्टगार्ड उपयुक्त ठरते.`,
            text3: `मस्टगार्ड चे फायदे.`,
            text4: `
            •⁠  ⁠कासेचे आजार होत नाहीत.
            •⁠  ⁠मस्टायटीस प्रतिजैविकांचा वापर न करता बरा होतो.
            •⁠  ⁠दुध उत्पादनात वाढ होते.
            •⁠  ⁠स्तनदाह लवकरात लवकर बरा होतो.
            •⁠  ⁠ट्रायसोडियम सायट्रेट, अमीनो अॅसिड, व्हिटॅमिन्स व वन औषधी युक्त.`,
        },
        {
            imagePath: "/assets/products-images-2/UTRI-HEALTH-1L.png",
            title: "UTRI-HEALTH-1L",
            description: "UTRI-HEALTH 1 LTR",
            description2: "",
            productNumber: "",
            contactLink: `${ContactWhatsappBaseHref}UTRI-HEALTH 1 LTR`,
            text1: `युट्री-हेल्थची गरज.`,
            text2: `जनावरे व्याल्यानंतर गर्भाशय साफ करण्यासाठी व वार पडण्यासाठी युट्री-हेल्थची गरज असते.`,
            text3: `युट्री-हेल्थचे फायदे.`,
            text4: `
            •  वार पाडण्यासाठी मदत होते.
            
            •⁠  ⁠व्याल्यानंतर गर्भाशय साफ होऊन रोगांपासून बचाव होतो.
            
            •⁠  ⁠जनावरे वेळेवर माजावर येतात.
            
            •⁠  ⁠जनावरांची रोगप्रतिकारक शक्ती वाढते.`,
        },
        {
            imagePath: "/assets/products-images-2/PREGAEASY-500GM.png",
            title: "PREGAEASY-500GM",
            description: "PREGAEASY 500 GM",
            description2: "",
            productNumber: "",
            contactLink: `${ContactWhatsappBaseHref}PREGAEASY 500 GM`,
            text1: `प्रेगाइझी ची गरज.`,
            text2: `जनावरांची गरज लक्षात घेता. आजारपणातील औषधांचा अतिरेक वापर व सकस आहाराची कमतरता यामुळे जनावरांच्या गर्भाशय वाढीवर विपरीत परिणाम होतात. जनावरांची गाभण प्रक्रिया सुरळीत चालण्यासाठी प्रेगाइझीचा वापर होतो.`,
            text3: `प्रेगाइझी चे फायदे.`,
            text4: ` 
            •⁠  ⁠गर्भाशयाची वाढ होण्यास मदत होते.
            
            •⁠  ⁠गर्भपात टाळण्यास मदत होते.
            
            •⁠  ⁠वारंवार होणाऱ्या रेतन प्रक्रियेचा खर्च वाचतो.
            
            •⁠  ⁠संसर्गजन्य रोगांचा प्रसार टाळण्यासाठी मदत होते.
            
            •⁠  ⁠भाकड काळ कमी होतो.`,
        },
        {
            imagePath: "/assets/products-images-2/FAT-GAINER.png",
            title: "FAT-GAINER",
            description: "FAT - GAINER",
            description2: "",
            productNumber: "",
            contactLink: `${ContactWhatsappBaseHref}FAT - GAINER`,
            text1: `फॅट गेनर ची गरज.`,
            text2: `दुध धंद्याला वलय आणि नफा मिळवून देण्यासाठी दुधाला उच्च दर मिळणे आवश्यक आहे. यासाठी उच्च आणि उत्तम प्रतीचे दर्जेदार दुध निर्मितीसाठी फॅट गेनरचा वापर गरजेचा आहे.`,
            text3: `फॅट गेनरचे फायदे.`,
            text4: `•⁠  ⁠दुधाळ गाईचे फॅट वाढवते.
            
            •⁠  ⁠दिलेल्या आहाराचा दुधात फॅटचे प्रमाण वाढण्यास मदत होते.
            
            •⁠  ⁠S.N.F. वाढीसाठी मदत होते.
            
            •⁠  ⁠दुधाचे प्रमाण वाढते व सातत्य टिकवून ठेवते
            
            •⁠  ⁠उत्पादन वाढण्यास मदत होते.`,
        },
    ]

export const bannerImagesList: BannerImages[] = [
    {
        sortOrder: 8,
        imagePath: "/assets/slider-images/products/BIO-KOL-BANNER.png",
        alt: "BIO-KOL-BANNER",
        desc: "Bio Kol"
    },
    {
        sortOrder: 7,
        imagePath: "/assets/slider-images/products/CALCIVET-GET-BANNER.png",
        alt: "CALCIVET-GET-BANNER",
        desc: "Calcivet Gel"
    },
    {
        sortOrder: 1,
        imagePath: "/assets/slider-images/products/CALCIVET-GOLD-PLUS-BANNER.png",
        alt: "CALCIVET-GOLD-PLUS-BANNER",
        desc: "Calcivet Gold Plus"
    },
    {
        sortOrder: 6,
        imagePath: "/assets/slider-images/products/DYZACURE-BANNER.png",
        alt: "DYZACURE-BANNER",
        desc: "Dyzacure"
    },
    {
        sortOrder: 14,
        imagePath: "/assets/slider-images/products/FAT-GAINER-BANNER.png",
        alt: "FAT-GAINER-BANNER",
        desc: "Fat Gainer"
    },
    {
        sortOrder: 2,
        imagePath: "/assets/slider-images/products/GROW-WELL-BANNER.png",
        alt: "GROW-WELL-BANNER",
        desc: "Grow Well"
    },
    {
        sortOrder: 4,
        imagePath: "/assets/slider-images/products/LIV-CARE-BANNER.png",
        alt: "LIV-CARE-BANNER",
        desc: "Liv Care"
    },
    {
        sortOrder: 9,
        imagePath: "/assets/slider-images/products/MILK-BOOSTER-BANNER.png",
        alt: "MILK-BOOSTER-BANNER",
        desc: "Milk Booster"
    },
    {
        sortOrder: 3,
        imagePath: "/assets/slider-images/products/MULTIVITA-BANNER.png",
        alt: "MULTIVITA-BANNER",
        desc: "Multivita"
    },
    {
        sortOrder: 11,
        imagePath: "/assets/slider-images/products/MUSTGARD-BANNER.png",
        alt: "MUSTGARD-BANNER",
        desc: "Mustgard"
    },
    {
        sortOrder: 13,
        imagePath: "/assets/slider-images/products/PREGAEASY-BANNER.png",
        alt: "PREGAEASY-BANNER",
        desc: "PregaEasy"
    },
    {
        sortOrder: 12,
        imagePath: "/assets/slider-images/products/UTRI-HEALTH-BANNER.png",
        alt: "UTRI-HEALTH-BANNER",
        desc: "Utri Health"
    },
    {
        sortOrder: 10,
        imagePath: "/assets/slider-images/products/V-PHOS-PRO-BANNER.png",
        alt: "V-PHOS-PRO-BANNER",
        desc: "V-Phos Pro"
    },
    {
        sortOrder: 5,
        imagePath: "/assets/slider-images/products/VITAL-SUPER-H-BANNER.png",
        alt: "VITAL-SUPER-H-BANNER",
        desc: "Vital Super H"
    },
];
