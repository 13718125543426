import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Product, products2 } from 'src/productList';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {

  productId: string;
  products: Product[] = products2;
  product: Product;
  constructor(private route: ActivatedRoute, private meta: Meta) { }


  ngOnInit() {
    this.route.params.subscribe(params => {
      this.productId = params['productId'];
      this.product = this.getProductById(this.productId);
    });
    // Update meta tags when product is loaded
    this.updateMetaTags();
  }
  updateMetaTags() {
    if (this.product) {
      this.meta.updateTag({ name: 'title', content: this.product.title }); // Update page title
      this.meta.updateTag({ name: 'description', content: this.product.description }); // Update meta description
      // Add more meta tags as needed
      if (this.product) {
        this.meta.updateTag({ property: 'og:title', content: this.product.title }); // Update OG title
        this.meta.updateTag({ property: 'og:description', content: this.product.description }); // Update OG description
        this.meta.updateTag({ property: 'og:image', content: this.product.imagePath }); // Update OG image
      }
    }
  }
  getProductById(productId: string): Product {
    var selectedProduct = products2.find(product => product.title == productId);

    if (selectedProduct) {
      console.log(selectedProduct)
      return selectedProduct
    }
    else {
      var subProduct;
      products2.forEach(product => {
        if (product.subProducts && product.subProducts.length > 0) {
          product.subProducts.forEach(sp => {
            if (sp.title === productId) {
              subProduct = sp;
              subProduct.text1 = product.text1;
              subProduct.text2 = product.text2;
              subProduct.text3 = product.text3;
              subProduct.text4 = product.text4;
            }
          })
        }
      });
      return subProduct;
    }
  }

  convertTextToHTMLList(text) {
    if (!text) return "";
    // Split the text based on the •⁠ symbol and filter out empty entries
    const sentences = text.split('•⁠').map(sentence => sentence.trim()).filter(sentence => sentence.length > 0);

    // Generate the HTML for the unordered list (<ul>)
    let htmlList = '<ul>';

    // Loop through each sentence and create a list item (<li>) for each
    sentences.forEach(sentence => {
      htmlList += `<li>${sentence}</li>`;
    });

    // Close the unordered list tag
    htmlList += '</ul>';

    // Return the generated HTML list
    return htmlList;
  }

}
