import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['./featured-products.component.css']
})
export class FeaturedProductsComponent implements OnInit {

  constructor() { }

  featuredProducts = [
    {
      imagePath: "/assets/products-images-2/CALCIVET-GOLD-1L.png",
      description: "CALCIVET GOLD PLUS 1 LTR",
      title: 'CALCIVET-GOLD-1L',
    },
    {
      imagePath: "/assets/products-images-2/CALCIVET-300ML.png",
      title: "CALCIVET-300ML",
      description: "CALCIVET 300ml",
    },
    {
      imagePath: "/assets/products-images-2/MULTIVITA-1KG.png",
      title: "MULTIVITA-STRONG-1KG",
      description: "MULTIVITA STRONG 1 KG",
    },
    {
      imagePath: "/assets/products-images-2/DYZACURE-300ML.png",
      title: "DYZACURE-300ML",
      description: "DYZACURE 300ml",
    }
  ];

  ngOnInit() {
  }

}
