import { Component, OnInit } from '@angular/core';
import { bannerImagesList } from 'src/productList';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  bannerImages = bannerImagesList.sort((a, b) => a.sortOrder - b.sortOrder);

  constructor() { }

  ngOnInit() {
  }

}
