import { Component, OnInit } from '@angular/core';
import { Product, products } from 'src/productList';

@Component({
  selector: 'app-products-slider',
  templateUrl: './products-slider.component.html',
  styleUrls: ['./products-slider.component.css']
})
export class ProductsSliderComponent implements OnInit {

  constructor() { }
  products: Product[] = products;

  ngOnInit() {
  }

}
