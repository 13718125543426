import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  constructor() { }
  message = "";
  isDisabled = true;
  year = 2024;
  ngOnInit() {
    const d = new Date();
    this.year = d.getFullYear();
  }
  onChange(message) {
    this.message = message;

    if (this.message === "") {
      this.isDisabled = true
    } else {
      this.isDisabled = false
    }
  }
  sendMessage() {
    window.location.href = "https://wa.me/919325560646/?text=Hi, I am contacting from our website, " + this.message;
  }
  sendEmail() {
    window.location.href = "mailto:beingvetpro@gmail.com?subject=Inquiry&body=Hello, I am contacting from our website, " + this.message;
  }
}
