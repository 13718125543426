import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brouse-catalog',
  templateUrl: './brouse-catalog.component.html',
  styleUrls: ['./brouse-catalog.component.css']
})
export class BrouseCatalogComponent implements OnInit {

  constructor() { }
  ngOnInit() {
  }

}
