import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { OurServicesComponent } from "./our-services/our-services.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ContactUsPageComponent } from "./contact-us-page/contact-us-page.component";
import { ProductListComponent } from "./product-list/product-list.component";
import { ProductDetailsComponent } from "./product-details/product-details.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },

  {
    path: "home",
    component: HomeComponent,
    data: {
      title: `Home`,
      description: `"Welcome to VetPro, your trusted partner in enhancing the health and productivity of your beloved pet animals. At
        VetPro, we specialize in developing innovative products formulated to support optimal milk production and
        immunity in pets."`,
      keywords:
        "VetPro Pharma",
    },
  },
  {
    path: "ourservices",
    component: OurServicesComponent,
    data: {
      title: "Our Services",
      description: `
      Advanced Nutritional Supplements: Offering a range of specialized supplements formulated to enhance milk production and boost immunity in pet animals.`,
      keywords:
        "VetPro Pharma",
    },
  },
  {
    path: "about-us",
    component: AboutUsComponent,
    data: {
      title: "About Us",
      description: `Welcome to VetPro, your trusted partner in enhancing the health and productivity of your beloved pet animals. At
        VetPro, we specialize in developing innovative products formulated to support optimal milk production and
        immunity in pets.`,
      keywords:
        "VetPro Pharma",
    },
  },
  {
    path: "contact-us",
    component: ContactUsPageComponent,
    data: {
      title: "Contact Us",
      description: `Welcome to VetPro, your trusted partner in enhancing the health and productivity of your beloved pet animals. At
        VetPro, we specialize in developing innovative products formulated to support optimal milk production and
        immunity in pets.`,
      keywords:
        "VetPro Pharma",
    },
  },
  {
    path: "products",
    component: ProductListComponent,
    data: {
      title: "Products",
      description: `Welcome to VetPro, your trusted partner in enhancing the health and productivity of your beloved pet animals. At
        VetPro, we specialize in developing innovative products formulated to support optimal milk production and
        immunity in pets.`,
      keywords:
        "VetPro Pharma",
    },
  },
  {
    path: "product/:productId",
    component: ProductDetailsComponent,
    data: {
      title: "Products",
      description: `Welcome to VetPro, your trusted partner in enhancing the health and productivity of your beloved pet animals. At
        VetPro, we specialize in developing innovative products formulated to support optimal milk production and
        immunity in pets.`,
      keywords:
        "VetPro Pharma",
    },
  },
  { path: "404", component: PageNotFoundComponent },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
