import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product, products2 } from 'src/productList';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  appRouter: Router;
  products: Product[] = products2;
  currentlySelected: Map<String, Product> = new Map<String, Product>();
  selectedOption: any;
  constructor(appRouter: Router) {
    this.appRouter = appRouter;
  }

  ngOnInit() {
    products2.forEach(product => {
      if (product.subProducts) {
        this.currentlySelected.set(product.title, product.subProducts[0])
      }
    });
  }

  onSelectCustom(selected: string) {
    var selects = selected.split(',');
    var selectedProduct = products2.filter(p => p.title == selects[0])[0];
    var selectedSubProduct = selectedProduct.subProducts.filter(sb => sb.title == selects[1])[0];
    this.currentlySelected.set(selectedProduct.title, selectedSubProduct)
  }


  getSelectedSubProduct(product: String): Product {
    return this.currentlySelected.get(product);
  }

  convertTextToHTMLList(text) {
    // Split the text based on the •⁠ symbol and filter out empty entries
    const sentences = text.split('•⁠').map(sentence => sentence.trim()).filter(sentence => sentence.length > 0);

    // Generate the HTML for the unordered list (<ul>)
    let htmlList = '<ul>';

    // Loop through each sentence and create a list item (<li>) for each
    sentences.forEach(sentence => {
      htmlList += `<li>${sentence}</li>`;
    });

    // Close the unordered list tag
    htmlList += '</ul>';

    // Return the generated HTML list
    return htmlList;
  }

  redirectToPdp(url) {
    this.appRouter.navigateByUrl("product/" + url);
    console.log(url);
  }

}
